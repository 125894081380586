import { admin, AdminState } from "./admin/state";
import { auth } from "./auth/state";
import { AppStateInfo, AuthState } from "./types";
import { ui, UIState } from "./ui/state";
import { auto, AutoState } from "./auto/state";
import { home } from "./home/state";
import { HomeState } from "./home/types";
import { wizard } from "./wizard/state";
import { WizardState } from "./wizard/types";
import { wizardNoPersist } from "./wizardNoPersist/state";
import { WizardNoPersistState } from "./wizardNoPersist/types";
import { LifeState } from "./life/types";
import { life } from "./life/state";

// Export Actions, Selectors, Types
export * from "./admin/actions";
export * from "./admin/selectors";
export * from "./auth/actions";
export * from "./auth/selectors";
export * from "./home/actions";
export * from "./home/selectors";
export * from "./ui/actions";
export * from "./ui/selectors";
export * from "./auto/actions";
export * from "./auto/selectors";
export * from "./life/actions";
export * from "./life/selectors";
export * from "./wizard/actions";
export * from "./wizard/selectors";
export * from "./wizardNoPersist/actions";
export * from "./wizardNoPersist/selectors";

export interface AppState {
  admin: AdminState;
  auth: AuthState;
  auto: AutoState;
  home: HomeState;
  life: LifeState;
  ui: UIState;
  wizard: WizardState;
  wizardNoPersist: WizardNoPersistState;
}

const states: AppStateInfo[] = [
  // Export States
  admin,
  auth,
  auto,
  home,
  life,
  ui,
  wizard,
  wizardNoPersist,
];
export default states;
