import {
  isToday,
  parseISO,
  isYesterday,
  isSameDay,
  subDays,
  endOfDay,
  isBefore,
  startOfDay,
} from "date-fns";

const locale = "en-US";

export const totalCalcFunc = (rows, field) =>
  rows.reduce((sum, row) => Number(row.original[field] || 0) + Number(sum), 0);

export function formatCurrency(number, currency = "USD") {
  if (!number) return "None";
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
  })
    .format(number)
    .replace(/\D00(?=\D*$)/, "");
}
export function formatDwellingCurrency(number, currency = "USD") {
  if (!number) return "";
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
  })
    .format(number)
    .replace(/\D00(?=\D*$)/, "");
}

export function roundToThousand(num) {
  return Math.round(num / 1000) * 1000;
}

export function roundToHundredth(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function formatNumber(num) {
  return new Intl.NumberFormat(locale).format(Number(num));
}

export function getPhoneNumbersOnly(value) {
  return ("" + (value || "")).replace(/\D/g, "");
}

export function formatPhone(value: string | number) {
  const cleaned = getPhoneNumbersOnly(value);
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
}

export function numberToFixed(total, digits) {
  return formatNumber(total.toFixed(digits));
}

export function kFormatter(num: number) {
  return (
    "$" +
    (Math.abs(num) > 999
      ? Math.sign(num) * (Math.round(Math.abs(num) / 100) / 10) + "k"
      : Math.sign(num) * Math.abs(num))
  );
}

export function numberDriver(index) {
  switch (index) {
    case 1:
      return "First";
    case 2:
      return "Second";
    case 3:
      return "Third";
    case 4:
      return "Fourth";
    case 5:
      return "Fifth";
    default:
      if (index % 10 === 1 && index % 100 !== 11) {
        return index + "st";
      }
      if (index % 10 === 2 && index % 100 !== 12) {
        return index + "nd";
      }
      if (index % 10 === 3 && index % 100 !== 13) {
        return index + "rd";
      }
      return index + "th";
  }
}

export function formatTitleCase(txt) {
  if (!txt) return "";
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

export function getDwellingAmount(type, amount) {
  switch (type) {
    case "C":
      if (amount < 30000) {
        return 30000;
      } else {
        return amount;
      }
    case "D":
    case "T":
    case "X":
      if (amount < 100000) {
        return 100000;
      } else {
        return amount;
      }
    default:
      return amount;
  }
}
export function getHeatingUpdate(yearOfConstruction) {
  if (yearOfConstruction < new Date().getFullYear() - 20) {
    return new Date().getFullYear() - 20;
  } else {
    return yearOfConstruction;
  }
}

export function phoneFormatter(input) {
  //returns (###) ###-####
  input = input.replace(/\D/g, "");
  const size = input.length;
  if (size > 0) {
    input = "(" + input;
  }
  if (size > 3) {
    input = input.slice(0, 4) + ") " + input.slice(4, 11);
  }
  if (size > 6) {
    input = input.slice(0, 9) + "-" + input.slice(9);
  }
  return input;
}

export function getReferralToken(user) {
  const referral_token = user.user_agency.find(
    (ua) => ua.agency.domain === window.location.hostname,
  )?.referral_token;
  return referral_token;
}

export const getDay = (date) => {
  return isToday(parseISO(date))
    ? "Today"
    : isYesterday(parseISO(date))
    ? "Yesterday"
    : isSameDay(new Date(date), subDays(new Date(), 2))
    ? "2 days ago"
    : isSameDay(new Date(date), subDays(new Date(), 3))
    ? "3 days ago"
    : "3+ days ago";
};

export const invalidDates = (start, end, setError) => {
  if (
    isBefore(
      new Date(startOfDay(new Date(start))),
      new Date(endOfDay(new Date(end))),
    ) === false
  ) {
    setError(true);
    return true;
  } else return false;
};

export const ensureHttps = (url) => {
  if (url) {
    return url?.includes("https://") ? url : "https://" + url;
  }
  return "";
};

export const replaceWhiteSpaceWithPlus = (value) => {
  return value.replace(/\s/g, "+");
};

export function formatSplitCase(input: string) {
  const inputSplit = input
    .split("_")
    .map((s) => s.replace("_", " "))
    .join(" ");
  return inputSplit.charAt(0).toUpperCase() + inputSplit.slice(1);
}
