import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  autoActions,
  autoSelectors,
  homeActions,
  homeSelectors,
  uiSelectors,
  useAction,
  wizardActions,
  wizardSelectors,
  wizardNoPersistSelectors,
} from "../../../state";
import ContactCards from "../../../pages/wizard/selectedQuote/ContactCards";
import { KeyboardArrowDown, KeyboardArrowUp } from "../../../components/icons";
import { Navigation } from "../../../lib";
import { useMobile } from "../../../themes";
import AutoCustomQuoteForm from "../auto/custom/CustomQuoteForm";
import HomeCustomQuoteForm from "../home/custom/CustomQuoteForm";
import { AddIcon } from "../../../components/icons";
import LoadingCard from "./cards/LoadingCard";
import { QuoteButtons } from "./QuoteButtons";
import QuoteCard from "./cards/QuoteCard";
import { useStyles } from "./Quotes.styles";
import ShowMoreCoverages from "./ShowMoreCoverages";
import { Alert } from "@mui/material";

export default function QuotesCarousel({
  customQuoteModal,
  handleCloseModal,
  handleCustomQuoteModal,
  showingBundled,
  showingMore,
  setShowingMore,
}) {
  // refs
  const CarouselRef = useRef(null);
  const seeMoreQuotesRef = useRef<HTMLDivElement>(null);
  // actions
  const clearSelectedAutoQuote = useAction(autoActions.clearSelectedAutoQuote);
  const clearSelectedHomeQuote = useAction(homeActions.clearSelectedHomeQuote);
  const setCarouselMode = useAction(wizardActions.setCarouselMode);
  const upgradeToBundle = useAction(wizardActions.upgradeToBundle);
  // selectors
  const allQuotes = useSelector(wizardSelectors.allQuotes);
  const autoQuotes = useSelector(autoSelectors.quotes);
  const bundleAutoQuotes = useSelector(autoSelectors.bundleQuotes);

  const bundleHomeQuotes = useSelector(homeSelectors.bundleQuotes);
  const bundleAutoQuotesFailed = useSelector(autoSelectors.bundleQuotesFailed);
  const bundleHomeQuotesFailed = useSelector(homeSelectors.bundleQuotesFailed);
  const bundleQuotesFailed = bundleAutoQuotesFailed || bundleHomeQuotesFailed;
  const carouselMode = useSelector(wizardSelectors.carouselMode);
  const customQuotesLoading = useSelector(wizardSelectors.customQuotesLoading);
  const homeQuotes = useSelector(homeSelectors.quotes);
  const isQuoteSelected = useSelector(wizardSelectors.isQuoteSelected);

  const latestBundleCustomQuote = useSelector(
    wizardSelectors.latestBundleCustomQuote,
  );
  const latestCustomQuote = useSelector(wizardSelectors.latestCustomQuote);
  const loading = useSelector(uiSelectors.loading);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const quotesLoading = useSelector(wizardSelectors.quotesLoading);
  const req_uid = useSelector(wizardSelectors.req_uid);
  const selectedAutoQuote = useSelector(autoSelectors.selectedQuote);
  const selectedHomeQuote = useSelector(homeSelectors.selectedQuote);
  //state
  const [bundleBoxOpen, setBundleBoxOpen] = useState<boolean>(true);
  const [quoteCount, setQuoteCount] = useState(3);

  // consts
  const _CarouselRef: any = CarouselRef.current;
  const containerWidth = _CarouselRef?.containerRef
    ? _CarouselRef.containerRef.current.offsetWidth
    : 0;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const isTablet = useMobile("lg");
  const isQuotesLoading =
    carouselMode === "auto" && showingBundled ? false : quotesLoading;

  const isLoadingCustomQuote = () => {
    if (mode !== "bundle") return customQuotesLoading;

    const loading = showingBundled
      ? latestBundleCustomQuote.filter((quote) => quote.is_loading)
      : latestCustomQuote.filter((quote) => quote.is_loading);

    return customQuotesLoading && Boolean(loading.length);
  };
  const filterQuotes = (carrier) => {
    const filteredQuotes: any[] = [];
    let filteredQuotePerLevel;
    Object.values(allQuotes).map((quotesArray: any) => {
      filteredQuotePerLevel = quotesArray.filter(
        (quote) =>
          (quote.quote_type === carouselMode ||
            (quote.quote_type === "renters" && carouselMode === "home")) &&
          quote.carrier === carrier &&
          quote.is_linked === showingBundled,
      );
      filteredQuotes.push(...filteredQuotePerLevel);
    });

    //only take last custom quote from filtered quotes
    const filteredCustom = filteredQuotes.filter(
      (quote) => quote.insurance_level_id === 0,
    );
    const filteredWithoutCustom = filteredQuotes.filter(
      (quote) => quote.insurance_level_id !== 0,
    );

    const custom =
      filteredCustom && filteredCustom.length
        ? [filteredCustom[filteredCustom.length - 1]]
        : [];
    return [...filteredWithoutCustom, ...custom];
  };
  const filteredAutoQuotes = isQuoteSelected
    ? filterQuotes(
        selectedAutoQuote
          ? selectedAutoQuote.carrier
          : selectedHomeQuote.carrier,
      )
    : [...bundleAutoQuotes, ...latestBundleCustomQuote];
  const filteredHomeQuotes = isQuoteSelected
    ? filterQuotes(
        selectedAutoQuote
          ? selectedAutoQuote.carrier
          : selectedHomeQuote.carrier,
      )
    : [...bundleHomeQuotes, ...latestBundleCustomQuote];
  const carouselQuotes =
    mode === "auto"
      ? [...autoQuotes, ...latestCustomQuote]
      : mode === "home" || mode === "renters"
      ? [...homeQuotes, ...latestCustomQuote]
      : //bundled
      carouselMode === "auto"
      ? isQuotesLoading
        ? ["Basic", "Robust", "Super"]
        : showingBundled
        ? filteredAutoQuotes
        : [...autoQuotes, ...latestCustomQuote]
      : isQuotesLoading
      ? ["Basic", "Robust", "Super"]
      : showingBundled
      ? filteredHomeQuotes
      : [...homeQuotes, ...latestCustomQuote];

  const responsive = {
    four: {
      breakpoint: { max: 3000, min: 1536 },
      items: carouselQuotes.length,
    },
    three: {
      breakpoint: { max: 1536, min: 1240 },
      items: 3,
    },
    two: {
      breakpoint: { max: 1240, min: 600 },
      items: 2,
    },
    one: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    setQuoteCount(carouselQuotes.length);

    if (latestCustomQuote.length || latestBundleCustomQuote.length) {
      dispatch(wizardActions.setActiveQuoteCard(0));
    }
  }, [latestCustomQuote, latestBundleCustomQuote, carouselQuotes]);

  useEffect(() => {
    const _ref: any = CarouselRef && CarouselRef.current;
    if (carouselQuotes.length > 3 && !isMobile) {
      if (containerWidth < 813 && containerWidth !== 0) {
        CarouselRef &&
          CarouselRef.current &&
          _ref.goToSlide(carouselQuotes.length - 2);
      } else if (containerWidth < 1110 && containerWidth !== 0) {
        CarouselRef &&
          CarouselRef.current &&
          _ref.goToSlide(carouselQuotes.length - 3);
      }
    }
  }, [latestCustomQuote]);

  useEffect(() => {
    if (showingMore) {
      setTimeout(() => {
        seeMoreQuotesRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  }, [showingMore]);

  if (!carouselQuotes.length && !isQuoteSelected && !bundleQuotesFailed)
    return null;

  return !carouselQuotes.length ? (
    isQuoteSelected ? (
      <Box className={classes.noQuotesContainer}>
        <Typography>
          {`Hmm, there aren’t any ${
            carouselMode === "auto"
              ? "auto"
              : mode === "bundle"
              ? "homeowner’s"
              : "renters"
          } insurance options for the ${
            carouselMode === "auto"
              ? mode === "bundle"
                ? "home"
                : "renters"
              : "auto"
          } quote
        you chose.`}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (carouselMode === "auto") {
              clearSelectedHomeQuote();
              setCarouselMode("home");
            } else {
              clearSelectedAutoQuote();
              setCarouselMode("auto");
            }
          }}
        >
          {`Select another
          ${
            carouselMode === "home"
              ? "auto"
              : mode === "bundle"
              ? "home"
              : "renters"
          } quote`}
        </Button>
        {!showingBundled && (
          <p
            className={classes.bundleCheckoutBypass}
            onClick={() =>
              Navigation.go(
                `/${
                  selectedAutoQuote
                    ? "auto"
                    : mode === "bundle"
                    ? "home"
                    : "renters"
                }/wizard/quotes/${req_uid}`,
              )
            }
          >
            {`I just want ${
              selectedAutoQuote
                ? "auto"
                : mode === "bundle"
                ? "home"
                : "renters"
            } insurance`}
          </p>
        )}
      </Box>
    ) : bundleQuotesFailed ? (
      <Box>
        <p className={classes.bundleQuotesFailedTitle}>
          {`We need some more information to get you ${
            carouselMode === "auto" ? "an auto" : "a home"
          } insurance quote.`}
        </p>
        <div className={classes.bundleQuotesFailedContact}>
          <ContactCards
            title="How would like an insurance expert to reach out to you?"
            // snag={false}
          />
        </div>
      </Box>
    ) : null
  ) : (
    <>
      <Carousel
        key={carouselQuotes.length}
        containerClass={clsx(
          classes.carouselContainer,
          isMobile && (mode === "bundle" || mode === "auto-renters")
            ? "marginTop"
            : "",
        )}
        responsive={responsive}
        shouldResetAutoplay={false}
        autoPlay={false}
        arrows={!isMobile}
        renderButtonGroupOutside={isMobile}
        customButtonGroup={
          isMobile ? <QuoteButtons quotes={carouselQuotes} /> : undefined
        }
        ref={CarouselRef}
      >
        {carouselQuotes.map((quote, i) => {
          return (
            <Box key={i}>
              {(i === 3 && isLoadingCustomQuote()) || isQuotesLoading ? (
                <LoadingCard
                  mode={mode}
                  title={
                    i === 3 && customQuotesLoading
                      ? "Custom quote"
                      : `${quote} coverage`
                  }
                  carouselMode={carouselMode}
                />
              ) : (
                <>
                  <QuoteCard key={i} quote={quote} />
                  {showingMore &&
                    (showingBundled && isQuoteSelected ? null : (
                      <>
                        <div style={{ marginBlock: 48 }}></div>
                        <ShowMoreCoverages
                          quoteCount={quoteCount}
                          level={quote.insurance_level_id}
                          moreOptionsOpen={showingMore}
                          quotes={carouselQuotes}
                          showingBundled={showingBundled}
                        />
                      </>
                    ))}
                  <Modal
                    className={classes.modal}
                    open={customQuoteModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ className: classes.backdrop }}
                  >
                    <Fade in={customQuoteModal}>
                      <Paper
                        square
                        variant="outlined"
                        className={classes.paper}
                      >
                        {mode === "home" ||
                        carouselMode === "home" ||
                        mode === "renters" ? (
                          <HomeCustomQuoteForm
                            handleCloseModal={handleCloseModal}
                            bundled={showingBundled}
                            setFormValue={null}
                          />
                        ) : (
                          <AutoCustomQuoteForm
                            handleCloseModal={handleCloseModal}
                            bundled={showingBundled}
                            setFormValue={null}
                          />
                        )}
                      </Paper>
                    </Fade>
                  </Modal>
                </>
              )}
            </Box>
          );
        })}
      </Carousel>
      <Alert className={classes.alert} severity="success">
        Quote is subject to the accuracy of information provided, CLUE (claims
        report), MVR (driving record), etc...
      </Alert>
      <div id="show-more-ref" ref={seeMoreQuotesRef}></div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.quotesActionBtns}
      >
        {/* <div style={{ width: 180 }} /> */}
        <Grid container style={{ width: "auto" }}>
          {!showingMore &&
            !isQuotesLoading &&
            (showingBundled && isQuoteSelected ? null : (
              <Button
                className={classes.showMoreBtn}
                onClick={() => setShowingMore(!showingMore)}
                disabled={loading}
              >
                More Expensive Options
              </Button>
            ))}
          {(isTablet || mode === "bundle" || mode === "auto-renters") &&
            !isQuotesLoading && (
              <Button
                className={classes.showMoreBtn}
                onClick={handleCustomQuoteModal}
                disabled={loading}
              >
                <AddIcon style={{ fontSize: 18, marginRight: 4 }} />
                Customize Coverages
              </Button>
            )}
        </Grid>
        {(mode === "auto" || mode === "home") && isMobile && (
          <div className={classes.bundleBoxWrapper}>
            <div
              className={`bundleBoxCollapse
                      ${bundleBoxOpen ? "open" : ""}`}
              onClick={() => setBundleBoxOpen(!bundleBoxOpen)}
            >
              {bundleBoxOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </div>
            {bundleBoxOpen && (
              <div className={classes.bundleBox}>
                <p>Bundle Auto + Home Insurance & Save!</p>
                <Button
                  // variant="contained"
                  // color="primary"
                  onClick={() => {
                    upgradeToBundle(mode);
                    if (mode === "auto") {
                      Navigation.go("/bundle/wizard/details/form");
                    } else if (mode === "home") {
                      Navigation.go("/bundle/wizard/start");
                    }
                  }}
                >
                  Bundle + Save
                </Button>
              </div>
            )}
          </div>
        )}
        {/* TODO: make this work - CG */}
        {mode === "bundle" && (
          <div style={{ minWidth: 180 }}>
            {!showingBundled && isQuoteSelected && (
              <p
                className={classes.bundleCheckoutBypass}
                onClick={() =>
                  Navigation.go(
                    `/${
                      selectedAutoQuote
                        ? "auto"
                        : mode === "bundle"
                        ? "home"
                        : "renters"
                    }/wizard/quotes/${req_uid}`,
                  )
                }
              >
                {`I just want ${
                  selectedAutoQuote
                    ? "auto"
                    : mode === "bundle"
                    ? "home"
                    : "renters"
                } insurance`}
              </p>
            )}
          </div>
        )}
      </Grid>
    </>
  );
}
