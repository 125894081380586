import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Fade,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Typography,
} from "@mui/material";
import { Alert, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { history } from "../../../lib";
import { useStyles } from "./QuoteManagement.styles";
import { ApplicantSection } from "./ApplicantSection";
import { NODE_ENV } from "../../../config";
import CoverageWrapper from "./CoverageWrapper";
import CoverageWrapper_Bundle from "./CoverageWrapper_Bundle";
import { defaultTheme, useMobile } from "../../../themes";
import {
  ApartmentIcon,
  ArrowBackIcon,
  ArrowDropDownIcon,
  BusinessIcon,
  DirectionsCar,
  EmailIcon,
  HistoryIcon,
  HomeIcon,
  ImportExportIcon,
  PhoneIcon,
  PolicyIcon,
  EditIcon,
} from "../../../components";
import reliExchangeLoader from "../../../assets/reliExchange-loader.gif";
import { formatPhone, Navigation } from "../../../lib";
import { AdminPages } from "../";
import { LifeCoverageSection } from "./LifeCoverageSection";
import LifeIcon from "../../../assets/img/life/LifeIcon";
import { formatTitleCase } from "../../../utils";
import { QUOTE_STATUSES } from "../../../state/auto/types";
import { CoverageCard } from "./CoverageCard";
import MobileDrawer from "../components/MobileDrawer";
import { AlertsDrawer } from "../components/AlertsDrawer";

export const QuoteIcon = ({ quoteType }) => {
  const classes = useStyles({});
  return (
    <div>
      {(quoteType === "Auto" ||
        quoteType === "Bundle" ||
        quoteType === "AutoRenters") && (
        <DirectionsCar className={classes.carIcon} />
      )}
      {(quoteType === "Home" || quoteType === "Bundle") && <HomeIcon />}
      {quoteType === "Life" && (
        <div className={classes.lifeIcon}>
          <LifeIcon color={defaultTheme.palette.primary.main} />
        </div>
      )}
      {quoteType === "Renters" ||
        (quoteType === "AutoRenters" && <ApartmentIcon />)}
      {quoteType === "manual" && <PolicyIcon />}
    </div>
  );
};

const QuoteManagement = (props: any) => {
  const [status, setStatus] = useState<string>("");

  const classes = useStyles({ status });
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const [payFrequency, setPayFrequency] = useState<"installments" | "full">(
    "installments",
  );
  const [linked, setLinked] = useState(true);
  const loadingNewDriverQuotes = useSelector(
    adminSelectors.loadingNewDriverQuotes,
  );
  const bundleBindData = useSelector(adminSelectors.bundleBindData);
  const {
    applicant,
    agency,
    quoteType,
    quoteTypes,
    agent,
    quotes,
    quote_alerts: alerts,
  } = useSelector(adminSelectors.activeQuote);

  const [showBundleAlert, setShowBundleAlert] = useState(false);

  const role = useSelector(authSelectors.activeRole);
  const user_agencies = useSelector(authSelectors.user_agency);
  const directorArray = user_agencies
    .filter((ua) => ua.role === "sales_director" || ua.role === "sales_admin")
    .map((ua) => ua.agency_id);

  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const isBundle =
    quoteTypes.includes("Bundle") || quoteTypes.includes("AutoRenters");
  const isLife = quoteType === "Life";
  const {
    match: {
      params: { masterQuoteId },
    },
  } = props;

  const urlMode = quoteTypes.includes("AutoRenters")
    ? "auto-renters"
    : quoteType.toLowerCase();
  const hydrateLink = `${
    NODE_ENV !== "development"
      ? `https://${agent?.agency.domain || agency?.domain}`
      : `http://${window.location.host}`
  }/${urlMode}/wizard/hydrate-quote/${applicant.req_uid}`;

  const handleChange = (event, newValue) => {
    if (newValue) setPayFrequency(newValue);
  };

  const { hasWizardData } = applicant;

  const QuoteIcon = ({ quoteType }) => {
    return (
      <div>
        {(quoteType === "Auto" ||
          quoteType === "Bundle" ||
          quoteType === "AutoRenters") && (
          <DirectionsCar className={classes.carIcon} />
        )}
        {(quoteType === "Home" || quoteType === "Bundle") && <HomeIcon />}
        {quoteType === "Life" && (
          <div className={classes.lifeIcon}>
            <LifeIcon color={defaultTheme.palette.primary.main} />
          </div>
        )}
        {quoteType === "Renters" ||
          (quoteType === "AutoRenters" && <ApartmentIcon />)}
        {quoteType === "manual" && <PolicyIcon />}
      </div>
    );
  };

  const OwnersWrapper = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null,
    );
    const [open, setOpen] = React.useState(false);
    const [directorOpen, setDirectorOpen] = React.useState(false);
    const handleClick = (event, person) => {
      setAnchorEl(event.currentTarget);
      if (person === "agent") {
        setOpen((prev) => !prev);
        setDirectorOpen(false);
      } else {
        setDirectorOpen((prev) => !prev);
        setOpen(false);
      }
    };
    return (
      <Box className={classes.ownersWrapper}>
        {agent?.user ? (
          <Box
            className={classes.agentWrapper}
            onClick={(e) => handleClick(e, "agent")}
          >
            {agent?.user?.first_name.substring(0, 1)}
            {agent?.user?.last_name.substring(0, 1)}
          </Box>
        ) : (
          <Box className={classes.contactItem}>
            <ImportExportIcon />
            <Button
              color="primary"
              // className={classes.contactTxt}
              onClick={() =>
                dispatch(adminActions.setAgentTransferModalOpen(true))
              }
            >
              Assign ownership
            </Button>
          </Box>
        )}
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-end"}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Box className={classes.contactWrapper}>
                    <Box className={classes.agentViewTop}>
                      <Box className={classes.agentCircle}>
                        {agent?.user?.first_name.substring(0, 1)}
                        {agent?.user?.last_name.substring(0, 1)}
                      </Box>
                      <Box className={classes.agentMiddle}>
                        <Box className={classes.agentName}>
                          {agent?.user?.first_name +
                            " " +
                            agent?.user?.last_name}
                        </Box>
                        <Box>Application owner</Box>
                      </Box>
                      <Box>{/* <OpenInNewIcon /> */}</Box>
                    </Box>
                    {agent?.user?.email && (
                      <Box className={classes.contactItem}>
                        <EmailIcon />
                        <Typography className={classes.contactTxt}>
                          {agent?.user?.email}
                        </Typography>
                      </Box>
                    )}
                    {agent?.user?.phone && (
                      <Box className={classes.contactItem}>
                        <PhoneIcon />
                        <Typography className={classes.contactTxt}>
                          {formatPhone(agent?.user?.phone)}
                        </Typography>
                      </Box>
                    )}

                    <Box className={classes.contactItem}>
                      <BusinessIcon />
                      <Typography className={classes.contactTxt}>
                        {agent?.agency.name}
                      </Typography>
                    </Box>
                    <Box className={classes.contactItem}>
                      <ImportExportIcon />
                      <Button
                        color="primary"
                        // className={classes.contactTxt}
                        onClick={() =>
                          dispatch(adminActions.setAgentTransferModalOpen(true))
                        }
                      >
                        Transfer ownership
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>

        {/* <Box
        className={classes.directorWrapper}
        onClick={(e) => handleClick(e, "")}
      >
        FH
      </Box> */}
        <Popper
          open={directorOpen}
          anchorEl={anchorEl}
          placement={"bottom-end"}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => setDirectorOpen(false)}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography>The content Popper.</Typography>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Box>
    );
  };

  const ApplicantTitle = () => {
    return (
      <Box className={classes.headerWrapper}>
        {/* <Box>
       
        </Box> */}
        <Box className={classes.applicantTitleWrapper}>
          <Box
            className={classes.backNavigationWrapper}
            onClick={() => Navigation.go("/admin/applications")}
          >
            <ArrowBackIcon className={classes.backNavigationIcon} />
          </Box>
          <Box className={classes.nameWrapper}>
            <div>
              <Typography className={classes.applicantName}>
                {formatTitleCase(applicant.first_name)}{" "}
                {formatTitleCase(applicant.middle_name)}{" "}
                {formatTitleCase(applicant.last_name)}
              </Typography>
            </div>
          </Box>
          {!isMobile && <QuoteIcon quoteType={quoteType} />}
        </Box>
        {!isMobile && (
          <div className={classes.date}>
            <Typography className={classes.createdDate}>
              {new Date(applicant.created_at).toLocaleString()}
            </Typography>
          </div>
        )}
        {isMobile && (
          <div className={classes.flex}>
            {((!!activeAgencyId &&
              !!directorArray.includes(+applicant.agency_id)) ||
              role === "super_admin") && <OwnersWrapper />}
          </div>
        )}
      </Box>
    );
  };

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const DropDownComponent = () => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    useEffect(() => {
      setSelectedIndex(
        QUOTE_STATUSES.findIndex((s) => s.sub_status === applicant.sub_status),
      );
      const status = QUOTE_STATUSES[selectedIndex]?.status;

      setStatus(status);
    }, [applicant, selectedIndex, setSelectedIndex]);
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current?.contains(event.target)) {
        return;
      }

      setOpen(false);
    };
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
    const handleMenuItemClick = (event, index) => {
      const selectedStatus = QUOTE_STATUSES[index];
      if (!role || !activeAgencyId) return;
      if (selectedStatus.sub_status === "Won") {
        dispatch(
          adminActions.setQuoteStatus({
            status: "",
            sub_status: selectedStatus.sub_status,
          }),
        );
      } else {
        dispatch(adminActions.setQuoteStatus(selectedStatus));
      }

      dispatch(adminActions.setActivityModalOpen(true));
      setSelectedIndex(index);
      setOpen(false);
    };

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
            <Button className={classes.buttons}>
              {QUOTE_STATUSES[selectedIndex]?.status} -{" "}
              {QUOTE_STATUSES[selectedIndex]?.sub_status}
            </Button>
            <Button
              disabled={applicant.sub_status === "Won"}
              size="small"
              onClick={handleToggle}
              className={classes.buttons}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement={"bottom-end"}
            transition
            className={classes.popper}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {QUOTE_STATUSES.map((status, index) => (
                        <MenuItem
                          key={status.sub_status}
                          disabled={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          <div className={`${status.status} statuses`}>
                            {status.status}
                          </div>
                          {status.sub_status}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  };
  const MultiPolicy = () => {
    return (
      <Box className={classes.multiSwitchWrapper}>
        <Typography className={classes.switchTxt}>Multi-Policy</Typography>
        <Switch
          checked={linked}
          onChange={(e, value) => {
            dispatch(adminActions.setBundleBindData({}));
            setLinked(value);
            setShowBundleAlert(false);
          }}
          color="primary"
          name="linked"
          size={isMobile ? "small" : "medium"}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (masterQuoteId === "new") {
      const applicantObj = {
        first_name: applicant.first_name,
        middle_name: applicant.middle_name,
        last_name: applicant.last_name,
        gender: applicant.gender,
        dob: applicant.dob,
      };
      dispatch(
        adminActions.getNewDriverQuoteID(applicant.req_uid, applicantObj),
      );
    } else {
      dispatch(
        adminActions.getQuoteForAgent(masterQuoteId, role, activeAgencyId),
      );
    }
  }, [dispatch, masterQuoteId]);

  useEffect(() => {
    if (masterQuoteId === "new" && applicant?.udd_request_uuid) {
      Navigation.replace(AdminPages.quote_mgmt_page, {
        params: {
          masterQuoteId: applicant.req_uid,
        },
      });
    }
  }, [applicant]);

  useEffect(() => {
    dispatch(
      adminActions.setQuoteStatus({
        status: applicant.status,
        sub_status: applicant.sub_status,
      }),
    );
    setShowBundleAlert(false);
    dispatch(adminActions.setBundleBindData({}));
  }, [applicant]);

  useEffect(() => {
    if (quoteType === "Bundle") {
      if (!bundleBindData.auto && !bundleBindData.home) {
        return;
      }
      if (
        ((bundleBindData.auto && !bundleBindData.home) ||
          (bundleBindData.home && !bundleBindData.auto)) &&
        linked
      ) {
        setShowBundleAlert(true);
      } else {
        setShowBundleAlert(false);
        dispatch(adminActions.setBindModalOpen(true));
      }
    }
    if (quoteType === "AutoRenters") {
      if (!bundleBindData.auto && !bundleBindData.renters) {
        return;
      }
      if (
        ((bundleBindData.auto && !bundleBindData.renters) ||
          (bundleBindData.renters && !bundleBindData.auto)) &&
        linked
      ) {
        setShowBundleAlert(true);
      } else {
        setShowBundleAlert(false);
        dispatch(adminActions.setBindModalOpen(true));
      }
    }
  }, [bundleBindData, linked]);

  return (
    <Box className={classes.containerWrapper}>
      <Box className={classes.topWrapper}>
        {
          <Box className={classes.mobileTopWrapper}>
            {" "}
            <ApplicantTitle />{" "}
            {isMobile && (
              <div className={classes.drawerWrapper}>
                <MobileDrawer />
              </div>
            )}
          </Box>
        }
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.mobileDates}>
              <div>
                <QuoteIcon quoteType={quoteType} />
              </div>
              <div>
                <div>
                  <Typography className={classes.text}>Submitted</Typography>
                  <span className={classes.createdDate}>
                    {new Date(applicant.created_at).toLocaleString()}
                  </span>
                </div>
                {applicant.effective_date && (
                  <div>
                    <Typography className={classes.text}>Effective</Typography>
                    <span className={classes.createdDate}>
                      {new Date(applicant.effective_date).toLocaleDateString()}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Box>
              <AlertsDrawer alerts={alerts} />
            </Box>
          </Box>
        )}
        <Box className={classes.topRight}>
          {!isMobile && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <AlertsDrawer alerts={alerts} />
              <Button
                color="primary"
                onClick={() => dispatch(adminActions.setListModalOpen(true))}
              >
                <HistoryIcon className={classes.historyIcon} />
                Status activity
              </Button>
            </Box>
          )}
          <Box className={classes.dropDown}>
            <DropDownComponent />
          </Box>
          {!isMobile && quoteType === "manual" && (
            <Button
              variant="contained"
              color="primary"
              className={classes.editBtn}
              onClick={() =>
                history.push(`/admin/edit-application/${applicant.req_uid}`)
              }
            >
              <EditIcon />
            </Button>
          )}
          {!isMobile &&
            ((!!activeAgencyId &&
              !!directorArray.includes(+applicant.agency_id)) ||
              role === "super_admin") && <OwnersWrapper />}
        </Box>
      </Box>

      <Box className={classes.container}>
        {!isMobile && <ApplicantSection applicant={applicant} />}
        <hr className={classes.divider} />
        <Box className={classes.coverageWrapper}>
          {showBundleAlert && (
            <Box className={classes.alertWrapper}>
              <Alert className={classes.bundleAlert} severity="warning">
                Select both purchased auto + home quotes to mark this
                application as won. If only a single policy was purchased you
                must turn off multi-policy.
              </Alert>
            </Box>
          )}
          <Box className={classes.alertWrapper}>
            {quoteType !== "manual" ? (
              <Alert className={classes.alert} severity="success">
                {quoteType === "Life"
                  ? "Final rates are subject to Underwriting and possible Medical Exam"
                  : "Quote is subject to the accuracy of information provided, CLUE (claims report), MVR (driving record), etc..."}
              </Alert>
            ) : (
              <Typography>Manual application</Typography>
            )}
          </Box>
          {quoteType !== "manual" && (
            <Box className={classes.coverageHeader}>
              <ToggleButtonGroup
                className={classes.switcherGroup}
                exclusive
                size="large"
                value={payFrequency}
                onChange={handleChange}
              >
                <ToggleButton value={"installments"}>
                  Billed monthly
                </ToggleButton>
                <ToggleButton value={"full"}>Paid in full</ToggleButton>
              </ToggleButtonGroup>

              <Box className={classes.buttonWrapper}>
                {!isMobile && (
                  <>
                    {isBundle && <MultiPolicy />}
                    {hasWizardData && (
                      <Button
                        className={classes.button}
                        variant="contained"
                        href={hydrateLink}
                        target="_blank"
                      >
                        Edit in Wizard
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Box>
          )}
          {isMobile && isBundle && <MultiPolicy />}
          {loadingNewDriverQuotes ? (
            <div>
              <div className={classes.insuranceLogosBanner} />
              <Box className={classes.quotesLoadingIcon}>
                <img src={reliExchangeLoader} height="184"></img>
              </Box>
              <Typography className={classes.quotesLoadingSubtext}>
                Creating new application
              </Typography>
              <Typography className={classes.quotesLoadingText}>
                Getting quotes from 16 carriers...
              </Typography>
            </div>
          ) : isLife ? (
            <LifeCoverageSection payFrequency={payFrequency} />
          ) : quoteType === "manual" ? (
            <Box>
              <CoverageCard
                key={"quote"}
                quote={{
                  ...quotes[0],
                  displayPremium:
                    quotes[0].full_premium_override || quotes[0].total_premium,
                }}
                isLast={true}
                hasPurchased={null}
              />
            </Box>
          ) : !isBundle ? (
            <CoverageWrapper payFrequency={payFrequency} />
          ) : (
            <CoverageWrapper_Bundle
              payFrequency={payFrequency}
              isLinked={linked}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(QuoteManagement);
