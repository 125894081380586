import CreateUsersPage from "./CreateUsersPage";
import DashboardPage from "./DashboardPage";
import EditQuote from "./EditQuote";
import QuoteManagement_old from "./QuoteManagement_old/QuoteManagement";
import UsersPage from "./UsersPage";
import CarriersPage from "./CarriersPage";
import AddCarrierPage from "./AddCarrierPage";
import ProfilePage from "./ProfilePage";
import FAQsPage from "./FAQ/FAQsPage";
import CreateFAQPage from "./FAQ/CreateFAQPage";
import CreateFAQCategoryPage from "./FAQ/CreateFAQCategoryPage";
import StatesPage from "./StatesPage";
import AddStatePage from "./AddStatePage";
import CoveragePage from "./CoveragePage";
import AddCoveragePage from "./AddCoveragePage";
import EditCoveragePage from "./EditCoveragePage";
import ApplicationsPage from "./ApplicationsPage";
import UDDPage from "./UDDPage";
import EditStateRequirementPage from "./EditStateRequirementPage";
import Reports from "./Reports";
import ReportView from "./ReportView";
// import AllowedIpsPage from "./AllowedIPsPage";
import AddAllowedIpPage from "./AddAllowedIpPage";
import CoverageUIPage from "./CoverageUIPage";
import AddCoverageUIPage from "./AddCoverageUIPage";
import EditCoverageUIPage from "./EditCoverageUIPage";
import AgencyPage from "./AgencyPage";
import AddAgencyPage from "./AddAgencyPage";
import UserAgencyPage from "./UserAgencyPage";
import AddUserAgencyPage from "./AddUserAgencyPage";
import ReferralsListPage from "./ReferralsListPage";
import ReferralsPage from "./ReferralsPage";
import {
  BusinessCenterIcon,
  BusinessIcon,
  ContactsIcon,
  DataUsageIcon,
  DescriptionIcon,
  MapIcon,
  // NetworkLockedIcon,
  PeopleIcon,
  SettingsIcon,
  QuestionAnswerIcon,
  SubjectIcon,
  VerifiedUserIcon,
  AssessmentIcon,
  ListAltIcon,
  AutorenewIcon,
  DashboardIcon,
} from "../../components/icons";
import EditUserAgencyPage from "./EditUserAgencyPage";
import AgencySettingsPage from "../admin/Agency/AgencySettingsPage";
import { Analytics } from "./Analytics";
import QuoteManagement from "./QuoteManagement/QuoteManagement";
import AddProductPage from "./AddProductPage";
import ProductsPage from "./ProductsPage";
import EditUsersPage from "./EditUsersPage";
import AgentStatsPage from "./AgentStatsPage";
import AddApplicationPage from "./AddApplicationPage";
import ViewCarriersPage from "./ViewCarriersPage";

export const AdminPages = {
  dashboard: {
    pathExact: true,
    anon: false,
    path: "/admin/dashboard",
    title: "Dashboard",
    type: "PAGE_ADMIN_DASHBOARD",
    view: DashboardPage,
    icon: DashboardIcon,
    menuItemOrder: 1,
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "sales_director",
      "sales_admin",
    ],
  },
  entry: {
    pathExact: true,
    anon: false,
    path: "/admin",
    title: "Applications",
    type: "PAGE_ADMIN_DASHBOARD",
    view: DashboardPage,
  },
  faqs: {
    pathExact: true,
    anon: false,
    path: "/admin/faqs",
    title: "FAQs",
    type: "PAGE_ADMIN_FAQS",
    view: FAQsPage,
    icon: QuestionAnswerIcon,
    allowedRoles: ["super_admin"],
    menuItemOrder: 30,
  },
  createFAQCategory: {
    pathExact: true,
    anon: false,
    path: "/admin/faqs/create-category",
    title: "Add FAQ Category",
    type: "PAGE_ADMIN_CREATE_FAQ_CATEGORY",
    view: CreateFAQCategoryPage,
  },
  createFAQ: {
    pathExact: true,
    anon: false,
    path: "/admin/faqs/create",
    title: "Add FAQ",
    type: "PAGE_ADMIN_CREATE_FAQ",
    view: CreateFAQPage,
  },
  createUsers: {
    pathExact: true,
    anon: false,
    path: "/admin/users/create",
    title: "Users",
    type: "PAGE_ADMIN_CREATE_USER",
    view: CreateUsersPage,
  },
  editUsers: {
    pathExact: true,
    anon: false,
    path: "/admin/users/:id",
    title: "Users",
    type: "PAGE_ADMIN_EDIT_USER",
    view: EditUsersPage,
    allowedRoles: [
      "super_admin",
      "agency_admin",
      "super_agent",
      "sales_director",
      "sales_admin",
    ],
  },
  users: {
    pathExact: true,
    anon: false,
    path: "/admin/users",
    title: "Users",
    type: "PAGE_ADMIN_USERS",
    view: UsersPage,
    icon: PeopleIcon,
    menuItemOrder: 10,
    allowedRoles: [
      "super_admin",
      "agency_admin",
      "super_agent",
      "sales_director",
      "sales_admin",
      "agent",
      "affiliate_agent",
    ],
  },
  // allowedIPs: {
  //   pathExact: true,
  //   anon: false,
  //   path: "/admin/allowed-ips",
  //   title: "Allowed IPs",
  //   type: "PAGE_ADMIN_ALLOWED_IPS",
  //   view: AllowedIpsPage,
  //   icon: NetworkLockedIcon,
  //   menuItemOrder: 40,
  //   allowedRoles: ["super_admin"],
  // },
  settings: {
    pathExact: true,
    anon: false,
    path: "/admin/agency-settings",
    title: "Settings",
    type: "PAGE_ADMIN_AGENCY_SETTINGS",
    view: AgencySettingsPage,
    icon: SettingsIcon,
    menuItemOrder: 41,
    allowedRoles: [
      "super_admin",
      "agency_admin",
      "super_agent",
      "sales_director",
      "sales_admin",
    ],
  },
  createAllowedIP: {
    pathExact: true,
    anon: false,
    path: "/admin/allowed-ips/create",
    title: "Create Allowed IP",
    type: "PAGE_ADMIN_CREATE_ALLOWED_IP",
    view: AddAllowedIpPage,
    allowedRoles: ["super_admin"],
  },
  carriers: {
    pathExact: true,
    anon: false,
    path: "/admin/carriers",
    title: "Carriers",
    type: "PAGE_ADMIN_CARRIERS",
    view: CarriersPage,
    icon: BusinessIcon,
    menuItemOrder: 20,
    allowedRoles: [
      "super_admin",
      "super_agent",
      "admin",
      "sales_director",
      "sales_admin",
    ],
  },
  createCarriers: {
    pathExact: true,
    anon: false,
    path: "/admin/carriers/create",
    title: "Create Carrier",
    type: "PAGE_ADMIN_CREATE_CARRIERS",
    view: AddCarrierPage,
    allowedRoles: ["super_admin", "sales_admin"],
  },
  editCarrier: {
    pathExact: true,
    anon: false,
    path: "/admin/edit-carriers/:id",
    title: "Create Carrier",
    type: "PAGE_ADMIN_EDIT_CARRIERS",
    view: AddCarrierPage,
    allowedRoles: ["super_admin", "sales_admin"],
  },
  states: {
    pathExact: true,
    anon: false,
    path: "/admin/states",
    title: "States",
    type: "PAGE_ADMIN_STATES",
    view: StatesPage,
    icon: MapIcon,
    menuItemOrder: 17,
    allowedRoles: ["super_admin"],
  },
  createStates: {
    pathExact: true,
    anon: false,
    path: "/admin/states/create",
    title: "Create State",
    type: "PAGE_ADMIN_CREATE_STATE",
    view: AddStatePage,
    allowedRoles: ["super_admin"],
  },
  editStates: {
    pathExact: true,
    anon: false,
    path: "/admin/states/:id",
    title: "Edit State",
    type: "PAGE_ADMIN_EDIT_STATES",
    view: EditStateRequirementPage,
    allowedRoles: ["super_admin"],
  },
  agencies: {
    pathExact: true,
    anon: false,
    path: "/admin/agencies",
    title: "Agencies",
    type: "PAGE_ADMIN_AGENCIES",
    view: AgencyPage,
    icon: BusinessCenterIcon,
    menuItemOrder: 8,
    allowedRoles: ["super_admin"],
  },
  createAgency: {
    pathExact: true,
    anon: false,
    path: "/admin/agency/create",
    title: "Create Agency",
    type: "PAGE_ADMIN_CREATE_AGENCY",
    view: AddAgencyPage,
    allowedRoles: ["super_admin"],
  },
  editAgency: {
    pathExact: true,
    anon: false,
    path: "/admin/agency/:id",
    title: "Edit Agency",
    type: "PAGE_ADMIN_EDIT_AGENCY",
    view: AgencySettingsPage,
    allowedRoles: ["super_admin"],
  },
  userAgencies: {
    pathExact: true,
    anon: false,
    path: "/admin/user-agencies",
    title: "User Agencies",
    type: "PAGE_ADMIN_USER_AGENCIES",
    view: UserAgencyPage,
    icon: PeopleIcon,
    menuItemOrder: 14,
    allowedRoles: ["super_admin", "super_agent", "sales_admin"],
  },
  createUserAgency: {
    pathExact: true,
    anon: false,
    path: "/admin/user-agency/create",
    title: "Create User Agency",
    type: "PAGE_ADMIN_CREATE_USER_AGENCY",
    view: AddUserAgencyPage,
    allowedRoles: ["super_admin"],
  },
  editUserAgency: {
    pathExact: true,
    anon: false,
    path: "/admin/user-agency/:id",
    title: "Edit User Agency",
    type: "PAGE_ADMIN_EDIT_USER_AGENCY",
    view: EditUserAgencyPage,
    allowedRoles: ["super_admin"],
  },
  coverage: {
    pathExact: true,
    anon: false,
    path: "/admin/coverage",
    title: "Coverage",
    type: "PAGE_ADMIN_COVERAGE",
    view: CoveragePage,
    icon: VerifiedUserIcon,
    menuItemOrder: 24,
    allowedRoles: ["super_admin"],
  },
  createCoverage: {
    pathExact: true,
    anon: false,
    path: "/admin/coverage/create",
    title: "Create Coverage",
    type: "PAGE_ADMIN_CREATE_COVERAGE",
    view: AddCoveragePage,
    allowedRoles: ["super_admin"],
  },
  editCoverage: {
    pathExact: true,
    anon: false,
    path: "/admin/coverage/:id",
    title: "Edit Coverage",
    type: "PAGE_ADMIN_EDIT_COVERAGE",
    view: EditCoveragePage,
    allowedRoles: ["super_admin"],
  },
  coverageUI: {
    pathExact: true,
    anon: false,
    path: "/admin/coverage-ui",
    title: "CoverageUI",
    type: "PAGE_ADMIN_COVERAGE_UI",
    view: CoverageUIPage,
    icon: SubjectIcon,
    menuItemOrder: 38,
    allowedRoles: ["super_admin"],
  },
  createCoverageUI: {
    pathExact: true,
    anon: false,
    path: "/admin/coverage-ui/create",
    title: "Create Coverage UI",
    type: "PAGE_ADMIN_CREATE_COVERAGE_UI",
    view: AddCoverageUIPage,
    allowedRoles: ["super_admin"],
  },
  editCoverageUI: {
    pathExact: true,
    anon: false,
    path: "/admin/coverage-ui/:id",
    title: "Edit Coverage UI",
    type: "PAGE_ADMIN_EDIT_COVERAGE_UI",
    view: EditCoverageUIPage,
    allowedRoles: ["super_admin"],
  },
  profile: {
    pathExact: true,
    anon: false,
    path: "/admin/profile",
    title: "Profile",
    type: "PAGE_ADMIN_PROFILE",
    view: ProfilePage,
  },
  quote_mgmt_page_old: {
    anon: false,
    path: "/admin/quote-management-old/:masterQuoteId",
    title: "Quote Management",
    type: "PAGE_QUOTE_MGMT_OLD",
    view: QuoteManagement_old,
  },
  quote_mgmt_page: {
    anon: false,
    path: "/admin/quote-management/:masterQuoteId",
    title: "Quote Management",
    type: "PAGE_QUOTE_MGMT",
    view: QuoteManagement,
  },
  editQuote: {
    anon: false,
    path: "/admin/edit-quote/:masterQuoteId",
    title: "Edit Quote",
    type: "PAGE_EDIT_QUOTE",
    view: EditQuote,
  },
  applications: {
    anon: false,
    path: "/admin/applications",
    title: "Applications",
    type: "PAGE_APPLICATIONS",
    view: ApplicationsPage,
    icon: DescriptionIcon,
    menuItemOrder: 1,
  },
  udd: {
    anon: false,
    path: "/admin/udd",
    title: "Driver Lookup",
    type: "PAGE_UDD_REQUESTS",
    view: UDDPage,
    icon: ContactsIcon,
    menuItemOrder: 3,
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "sales_director",
      "sales_admin",
    ],
  },
  agentLink: {
    anon: false,
    path: "https://sites.google.com/a/ifsbrokerage.com/intranet/",
    title: "Agent Intranet",
    type: "Agent Link",
    icon: BusinessIcon,
    menuItemOrder: 7,
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "sales_director",
      "sales_admin",
    ],
  },
  viewReports: {
    anon: false,
    path: "/admin/reports/:id",
    title: "Report View",
    type: "PAGE_REPORTS_VIEW",
    view: ReportView,
    allowedRoles: ["super_admin"],
  },
  reports: {
    anon: false,
    path: "/admin/reports",
    title: "Reports",
    type: "PAGE_REPORTS",
    view: Reports,
    icon: DataUsageIcon,
    menuItemOrder: 27,
    allowedRoles: ["super_admin"],
  },
  analytics: {
    anon: false,
    path: "/admin/analytics",
    title: "Analytics",
    type: "PAGE_ANALYTICS",
    view: Analytics,
    icon: AssessmentIcon,
    menuItemOrder: 28,
    allowedRoles: ["super_admin", "sales_admin"],
  },
  // referralList: {},
  referrals: {
    anon: false,
    path: "/admin/referrals",
    title: "Referrals",
    type: "PAGE_REFERRALS_LIST",
    icon: AutorenewIcon, //TODO find icon
    view: ReferralsListPage,
    menuItemOrder: 5,
    // TODO: edit roles
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "referral_product_owner",
      "sales_director",
      "sales_admin",
    ],
  },
  agentStats: {
    anon: false,
    path: "/admin/stats",
    title: "Agent insights",
    type: "AGENT_STATS",
    icon: AssessmentIcon,
    view: AgentStatsPage,
    menuItemOrder: 6,
    allowedRoles: [
      "super_admin",
      "admin",
      "sales_director",
      "sales_admin",
      "agency_admin",
    ],
  },
  viewReferral: {
    anon: false,
    path: "/admin/referral-view/:referralId",
    title: "Referrals",
    type: "PAGE_REFERRALS",
    view: ReferralsPage,
    // TODO: edit roles
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "referral_product_owner",
      "sales_director",
      "sales_admin",
    ],
  },
  products: {
    pathExact: true,
    anon: false,
    path: "/admin/referral-products",
    title: "Referral products",
    type: "REFERRAL_PRODUCTS_PAGE",
    view: ProductsPage,
    icon: ListAltIcon,
    menuItemOrder: 25,
    allowedRoles: ["super_admin"],
  },
  viewAllCarriers: {
    pathExact: true,
    anon: false,
    path: "/admin/view-carriers",
    title: "View Carriers",
    type: "PAGE_ADMIN_ADD_VIEW_CARRIERS",
    icon: MapIcon,
    menuItemOrder: 23,
    view: ViewCarriersPage,
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "sales_director",
      "sales_admin",
    ],
  },
  addApplication: {
    pathExact: true,
    anon: false,
    path: "/admin/create-application",
    title: "Add application",
    type: "PAGE_ADMIN_ADD_APPLICATION",
    view: AddApplicationPage,
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "sales_director",
      "sales_admin",
    ],
  },
  editApplication: {
    pathExact: true,
    anon: false,
    path: "/admin/edit-application/:req_uid",
    title: "Edit application",
    type: "PAGE_ADMIN_EDIT_APPLICATION",
    view: AddApplicationPage,
    allowedRoles: [
      "super_admin",
      "super_agent",
      "agent",
      "agency_admin",
      "admin",
      "sales_director",
      "sales_admin",
    ],
  },
  addProducts: {
    pathExact: true,
    anon: false,
    path: "/admin/products/create",
    title: "Add product",
    type: "PAGE_ADMIN_CREATE_REFERRAL_PRODUCTS",
    view: AddProductPage,
    allowedRoles: ["super_admin"],
  },
  editProduct: {
    pathExact: true,
    anon: false,
    path: "/admin/products/:id",
    title: "Edit product",
    type: "PAGE_ADMIN_EDIT_Product",
    view: AddProductPage,
    allowedRoles: ["super_admin"],
  },
};

export default AdminPages;
export const AdminArea = {
  pages: AdminPages,
};
